import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

export default function PageTemplate({ data: { mdx } }) {
  return (
    <div className="styles-testclient-pharma">
      <MDXRenderer>{mdx.body}</MDXRenderer>
      <div data-iframe-height></div>
      <script
        type="text/javascript"
        src="/js/iframeresizer/4.2.9/iframeResizer.contentWindow.min.js"
      ></script>
    </div>
  );
}

export const pageQuery = graphql`
  query TestclientPharmaQuery($id: String) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`;
